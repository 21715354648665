import "./set-public-path";

const entorno ="dev"
const apiLhlDev="http://localhost:8201"
const apiS3UrlDev="http://localhost:8202"
const apiCatalogDev="http://localhost:8200"

// const apiUrl = "https://serviceslhladmin.lexis.com.ec"
const apiUrl = "https://servicessecure.lexis.com.ec"

let apiCatalogUrl, apiLhl, apiS3;
if (entorno==="prod"){
    apiCatalogUrl=apiCatalogDev;
    apiLhl=apiLhlDev;
    apiS3=apiS3UrlDev;
}
else{
    apiCatalogUrl=apiUrl;
    apiLhl=apiUrl;
    apiS3=apiUrl;
}

//-----LHL catalog endpoints-------
// AccessType Endpoints
const findAllAccessTypesUrl= apiCatalogUrl +"/api/v1/lhl/tipoacceso/findall" // Find all access types available
const findAccessByIdUrl= apiCatalogUrl +"/api/v1/lhl/tipoacceso/findbyid" // Find access types info by id
const saveAccessUrl= apiCatalogUrl +"/api/v1/lhl/tipoacceso/save" // Create a new access type
const updateAccessUrl= apiCatalogUrl +"/api/v1/lhl/tipoacceso/update" // Update an existing access type
const deleteAccessUrl= apiCatalogUrl +"/api/v1/lhl/tipoacceso/delete" // Delete an existing access type

// Services Endpoints
const findAllServicesUrl= apiCatalogUrl +"/api/v1/lhl/servicio/findall" // Find all services available
const findServiceByIdUrl= apiCatalogUrl +"/api/v1/lhl/servicio/findbyid" // Find service info by id
const findServiceByNameUrl= apiCatalogUrl +"/api/v1/lhl/servicio/findbyname" // Find service info by name
const saveServiceUrl= apiCatalogUrl +"/api/v1/lhl/servicio/save" // Create a new service
const updateServiceUrl= apiCatalogUrl +"/api/v1/lhl/servicio/update" // Update an existing service
const deleteServiceUrl= apiCatalogUrl +"/api/v1/lhl/servicio/delete" // Delete an existing service


//Categories endpoints
const lhlCategoriesUrl = apiCatalogUrl + "/api/v1/lhl/categorias" //Find all categories
const lhlSaveCategoryUrl = apiCatalogUrl + "/api/v1/lhl/savecategoria" //Create a new category
const lhlUpdateCategoryUrl = apiCatalogUrl + "/api/v1/lhl/updatecategoria" //Update an existing category
const lhlCategoryUrl = apiCatalogUrl + "/api/v1/lhl/categoria" //Find an existing category y id
const lhlDeleteCategoryUrl = apiCatalogUrl + "/api/v1/lhl/deletecategoria" //Update an existing category

//Subcategories endpoints
const lhlSubcategoriesUrl = apiCatalogUrl + "/api/v1/lhl/subcategorias" //Find all subcategories
const lhlSaveSubcategoriesUrl = apiCatalogUrl + "/api/v1/lhl/savesubcategoria" //Create a new subcategory
const lhlUpdateSubcategoryUrl = apiCatalogUrl + "/api/v1/lhl/updatesubcategoria" //Update an existing subcategory
const lhlSubcategoryUrl = apiCatalogUrl + "/api/v1/lhl/subcategoria" //Find an existing subcategory by id
const lhlDeleteSubcategoryUrl = apiCatalogUrl + "/api/v1/lhl/deletesubcategoria" //Delete an existing subcategory

//Subjects endpoints
const lhlMateriasUrl = apiCatalogUrl + "/api/v1/lhl/materias" //Find all subjects
const lhlSaveMateriasUrl = apiCatalogUrl + "/api/v1/lhl/savemateria" //Create a new subjects
const lhlUpdateMateriasUrl = apiCatalogUrl + "/api/v1/lhl/updatemateria" //Update an existing subjects
const lhlMateriaUrl = apiCatalogUrl + "/api/v1/lhl/materia" //Find an existing subjects by id
const lhlDeleteMateriasUrl = apiCatalogUrl + "/api/v1/lhl/deletemateria" //Delete an existing subjects

//Countries endpoints
const lhlPaisesUrl = apiCatalogUrl + "/api/v1/lhl/paises"
const lhlPaisUrl = apiCatalogUrl + "/api/v1/lhl/paises"


//-------LHL Admin endpoints----------
const lhlFindAllLibrosUrl = apiLhl + "/api/v1/lhl/findAll"
const lhlFindLibrosUrl = apiLhl + "/api/v1/lhl/findLibros"
const lhlDeleteLibroUrl = apiLhl + "/api/v1/lhl/deleteLibro"
const lhlSaveLibroUrl = apiLhl + "/api/v1/lhl/saveLibro"
const lhlEditLibroUrl = apiLhl + "/api/v1/lhl/updateLibro"
const lhlDownloadLibroUrl = apiLhl + "/api/v1/lhl/downloadLibro"


//-------- S3 admin endpoints----------------
const s3UploadUrl= apiS3 +"/api/v1/lhl/s3/upload"
const s3DownloadUrl= apiS3 +"/api/v1/lhl/s3/download"
const s3DeleteUrl= apiS3 +"/api/v1/lhl/s3/delete"


//-------- Access types functions----------------

//find all access types available
export const findAllAccessTypes = async () => {
    let response = await fetchData(findAllAccessTypesUrl, null)
    console.log("Api response:", response)
    return response;
}

//Find access type by id 
export const findAccessById = async (id) => {
    let body = { id }
    let response = await fetchData(findAccessByIdUrl, body)
    console.log("Api response:", response)
    return response;
}

//create a new access type
export const saveAccess = async (tipoAcceso) => {
    let body = { tipoAcceso }
    let response = await fetchData(saveAccessUrl, body)
    console.log("Api response:", response)
    return response;
}

//Update an existing access type
export const updateAccess = async (id, tipoAcceso) => {
    let body = { id, tipoAcceso }
    let response = await fetchData(updateAccessUrl, body)
    console.log("Api response:", response)
    return response;
}

//Delete an existing access type
export const deleteAccess = async (id) => {
    let body = { id }
    let response = await fetchData(deleteAccessUrl, body)
    console.log("Api response:", response)
    return response;
}



//-------- Services functions----------------

//find all services available
export const findAllServices = async () => {
    let response = await fetchData(findAllServicesUrl, null)
    console.log("Api response:", response)
    return response;
}

//Find service info by id 
export const findServiceById = async (id) => {
    let body = { id }
    let response = await fetchData(findServiceByIdUrl, body)
    console.log("Api response:", response)
    return response;
}

//Find service info by name 
export const findServiceByName = async (servicio) => {
    let body = { servicio }
    let response = await fetchData(findServiceByNameUrl, body)
    console.log("Api response:", response)
    return response;
}

//create a new service
export const saveService = async (servicio, idTipoAcceso) => {
    let body = { servicio, idTipoAcceso }
    let response = await fetchData(saveServiceUrl, body)
    console.log("Api response:", response)
    return response;
}

//Update an existing service
export const updateService = async (id, servicio, idTipoAcceso) => {
    let body = { id, servicio, idTipoAcceso }
    let response = await fetchData(updateServiceUrl, body)
    console.log("Api response:", response)
    return response;
}

//Delete an existing service
export const deleteService = async (id) => {
    let body = { id }
    let response = await fetchData(deleteServiceUrl, body)
    console.log("Api response:", response)
    return response;
}


//-------- Categories functions----------------

//fetch categories
export const lhlCategories = async () => {
    let response = await fetchData(lhlCategoriesUrl, null)
    console.log("Api response:", response)
    return response;
}

//save category
export const lhlSaveCategory = async (categoria, tooltip, icon, iconName, link, pro, accesos) => {
    let body = { categoria, tooltip, icon, iconName, pro, link, pro, accesos }
    let response = await fetchData(lhlSaveCategoryUrl, body)
    console.log("Api response:", response)
    return response;
}

//Update category
export const lhlUpdateCategory = async (id, categoria, tooltip, icon, iconName, link, pro, accesos) => {
    let body = { id, categoria, tooltip, icon, iconName, link, pro, accesos }
    let response = await fetchData(lhlUpdateCategoryUrl, body)
    console.log("Api response:", response)
    return response;
}

//Find category
export const lhlFindCategory = async (id) => {
    let body = { id }
    let response = await fetchData(lhlCategoryUrl, body)
    console.log("Api response:", response)
    return response;
}


//Delete category
export const lhlDeleteCategory = async (id) => {
    let body = { id }
    let response = await fetchData(lhlDeleteCategoryUrl, body)
    console.log("Api response:", response)
    return response;
}

//fetch subcategories
export const lhlSubcategories = async () => {
    let response = await fetchData(lhlSubcategoriesUrl, null)
    console.log("Api response:", response)
    return response;
}

//save subcategory
export const lhlSaveSubcategory = async (idCategoria, subcategoria, link) => {
    let body = { idCategoria, subcategoria, link }
    let response = await fetchData(lhlSaveSubcategoriesUrl, body)
    console.log("Api response:", response)
    return response;
}

//Update subcategory
export const lhlUpdateSubcategory = async (id, idCategoria, subcategoria, link) => {
    let body = { id, idCategoria, subcategoria, link }
    let response = await fetchData(lhlUpdateSubcategoryUrl, body)
    console.log("Api response:", response)
    return response;
}

//Find category
export const lhlFindSubcategory = async (id) => {
    let body = { id }
    let response = await fetchData(lhlSubcategoryUrl, body)
    console.log("Api response:", response)
    return response;
}


//Delete category
export const lhlDeleteSubcategory = async (id) => {
    let body = { id }
    let response = await fetchData(lhlDeleteSubcategoryUrl, body)
    console.log("Api response:", response)
    return response;
}

//fetch subjects
export const lhlMaterias = async () => {
    let response = await fetchData(lhlMateriasUrl, {})
    console.log("Api response:", response)
    return response;
}

//save subject
export const lhlSaveMateria = async (idSubcategoria, materia) => {
    let body = { idSubcategoria, materia }
    let response = await fetchData(lhlSaveMateriasUrl, body)
    console.log("Api response:", response)
    return response;
}

//Update subject
export const lhlUpdateMateria = async (id, idSubcategoria, materia) => {
    let body = { id, idSubcategoria, materia }
    let response = await fetchData(lhlUpdateMateriasUrl, body)
    console.log("Api response:", response)
    return response;
}

//Find subject
export const lhlFindMateria = async (id) => {
    let body = { id }
    let response = await fetchData(lhlMateriaUrl, body)
    console.log("Api response:", response)
    return response;
}


//Delete category
export const lhlDeleteMaterias = async (id) => {
    let body = { id }
    let response = await fetchData(lhlDeleteMateriasUrl, body)
    console.log("Api response:", response)
    return response;
}


//Find countries
export const lhlFindPaises = async () => {
    let response = await fetchData(lhlPaisesUrl, null)
    console.log("Api response:", response)
    return response;
}

//Find countries
export const lhlFindPais = async () => {
    let response = await fetchData(lhlPaisUrl, null)
    console.log("Api response:", response)
    return response;
}

//find documents
export const lhlFindAllLibros = async () => {    
    let response = await fetchData(lhlFindAllLibrosUrl, null)
    console.log("Api response:", response)
    return response;
}

//find documents per category
export const lhlFindLibros = async (idCategoria) => {
    let body = { idCategoria }
    let response = await fetchData(lhlFindLibrosUrl, body)
    console.log("Api response:", response)
    return response;
}

//Delete document
export const lhlDeleteLibro = async (id) => {
    let body = { id }
    let response = await fetchData(lhlDeleteLibroUrl, body)
    console.log("Api response:", response)
    return response;
}

//Save document
export const lhlSaveLibro = async (idCategoria, idSubcategoria, idMateria, idPais, titulo, s3Path, archivo, autor, anio, tema, editorial) => {
    let body = { idCategoria, idSubcategoria, idMateria, idPais, titulo, s3Path, archivo, autor, anio, tema, editorial }
    //console.log("body: ", body)
    let response = await fetchData(lhlSaveLibroUrl, body)
    //console.log("Api response:", response)
    //let response="test"
    return response;
}

export const lhlEditLibro = async (id, idCategoria, idSubcategoria, idMateria, idPais, titulo, s3Path, archivo, autor, anio, tema, editorial) => {
    let body = { id, idCategoria, idSubcategoria, idMateria, idPais, titulo, s3Path, archivo, autor, anio, tema, editorial }
    //console.log("body: ", body)
    let response = await fetchData(lhlEditLibroUrl, body)
    //console.log("Api response:", response)
    //let response="test"
    return response;
}

export const lhlDownloadLibro = async (id) => {
    let body = { id }
    let response = await fetchData(lhlDownloadLibroUrl, body)
    console.log("Api response:", response)
    return response;
}


export const s3UploadLibro=async(file, path)=>{
    var formData = new FormData();
    formData.append("file", file)
    formData.append("path", path) 
    console.log("data s3:", file, path)   
    let response = await fetchData2(s3UploadUrl, formData)
    console.log("Api response:", response)
    return response;
}

export const s3DownloadLibro=async(fileName)=>{
    let body = { fileName }
    let response = await fetchData(s3DownloadUrl, body)
    console.log("Api response:", response)
    return response;
}

export const s3DeleteLibro=async(fileName)=>{
    let body = { fileName }
    let response = await fetchData(s3DeleteUrl, body)
    console.log("Api response:", response)
    return response;
}
// Define states
export const apiStates = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
};

//This function fetches data from server
export const fetchData = async (url, bodyQuery) => {
    var fetchedData = "";

    //console.log("previous to fetch")
    await fetch(url, {
        method: "post",
        body: JSON.stringify(bodyQuery),
        headers: { "Content-Type": "application/json" },
    })
        .then((response) =>
            //console.log(response),
            response.ok
                ? response.json()
                : (fetchedData = {
                    state: apiStates.ERROR,
                    error: response,
                    data: null,
                })
        )
        .then((data) => {
            if (data.state !== "ERROR")
                fetchedData = {
                    state: apiStates.SUCCESS,
                    data,
                };
            //console.log(data)
        })
        .catch((err) => {
            //console.log(err)
            fetchedData = {
                state: apiStates.ERROR,
                error: err,
                data: null,
            };
        });

    return fetchedData;
};

export const fetchData2 = async (url, bodyQuery) => {
    var fetchedData = "";

    //console.log("previous to fetch")
    await fetch(url, {
        method: "post",
        body: bodyQuery,
        //headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) =>
            //console.log(response),
            response.ok
                ? response.json()
                : (fetchedData = {
                    state: apiStates.ERROR,
                    error: response,
                    data: null,
                })
        )
        .then((data) => {
            if (data.state !== "ERROR")
                fetchedData = {
                    state: apiStates.SUCCESS,
                    data,
                };
            //console.log(data)
        })
        .catch((err) => {
            //console.log(err)
            fetchedData = {
                state: apiStates.ERROR,
                error: err,
                data: null,
            };
        });

    return fetchedData;
};



// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() { }
